<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="10" lg="10"
        ><v-list two-line>
          <v-list outlined elevation="2" class="pa-0">
            <template>
              <v-toolbar min-width="100%" dark color="primary">
                <v-card-title>Notice Board</v-card-title>
              </v-toolbar>

              <DataTableSSR
                apiEndPoint="active_notice/"
                :headers="headers"
                :instantLoad="true"
              >
                <template #[`item.created_at`]="{ item }">
                  {{ new Date(item.created_at).toLocaleString() }}
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-btn color="primary" @click="viewNotice(item)">
                    View
                  </v-btn>

                  <v-dialog v-model="item.dialog" width="70%">
                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        {{ notice.title }}
                        <v-list-item-subtitle>
                          {{ new Date(notice.created_at).toLocaleString() }}
                        </v-list-item-subtitle>
                      </v-card-title>
                      <v-card-text>{{ notice.content }}</v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="closeDialog(item)">Close</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
              </DataTableSSR>

              <v-divider
                v-if="index < items.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list> </v-list
      ></v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTableSSR from "../../components/global/DataTableSSR.vue";
export default {
  components: { DataTableSSR },
  data: () => ({
    items: [],
    pagination: {},
    dialog: false,
    notice: {},
    headers: [
      {
        text: "Created At",
        value: "created_at",
      },
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Actions",
        value: "actions",
        align: "right",
      },
    ],
  }),
  created() {
    this.loadNotices();
  },

  methods: {
    async viewNotice(item) {
      await this.$api.get(`/active_notice/${item.id}/`).then((response) => {
        this.notice = response.data;
        item.dialog = true;
      });
    },
    closeDialog(item) {
    this.$set(item, 'dialog', false); 
    this.notice = {}; 
  },
    loadNotices() {
      this.$api
        .get("/active_notice/")
        .then((response) => {
          const { results, ...pagination } = response.data;
          this.items = results;
          this.pagination = pagination;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
